import React from 'react'
import { ClearButton, TextField } from './styles'

const FilterComponent = ({ filterText, onFilter, onClear, placeholder }) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder={placeholder}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton variant="outlined" type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
)

export default FilterComponent
