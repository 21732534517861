import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh;
    width: 100vw;

    display: grid;

    grid-template-columns: 225px auto;
    grid-template-rows: 50px auto;

    grid-template-areas:
        'LOGOSECTION SHELLBAR'
        'SIDENAV APPCONTAINER';
`
