import styled from 'styled-components'

export const Container = styled.div`
    grid-area: LOGOSECTION;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 4;
    padding: 0 17px;
    font-size: 14px;

    background: #e3e4e6;
    box-shadow:
        2px 0 5px rgba(102, 102, 102, 0.15),
        1px 0 4px rgba(102, 102, 102, 0.25);
`
