import React from 'react'

import Breadcrumb from './Breadcrumb'
import UserNav from './UserNav'

import { Container } from './styles'

const ShellBar = () => {
    return (
        <Container>
            <Breadcrumb />
            <UserNav />
        </Container>
    )
}

export default ShellBar
