import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useModalContext } from '../../../contexts/ModalContext'
import TextField from '@mui/material/TextField'
import { Container, FormContainer } from './styles'
import 'react-data-table-component-extensions/dist/index.css'
import * as MechanicsService from '../../../services/mechanics'
import * as StatesService from '../../../services/statesAndCities'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'

const NewMechanicModal = ({ updateTable }) => {
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const { setModal } = useModalContext('')
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [phone, setPhone] = useState('')

    useEffect(() => {
        async function getStates() {
            const response = await StatesService.getStates()
            setStates(response)
        }

        getStates()
    }, [])

    useEffect(() => {
        async function getCities() {
            const response = await StatesService.getCities(state)
            setCities(response)
        }

        getCities()
    }, [state])

    const handleClose = () => setModal(null)

    const addMechanic = async (event) => {
        event.preventDefault()

        const body = {
            name: name,
            address: address,
            state: state,
            city: city,
            phone: phone,
        }

        await MechanicsService.saveMechanic(body)
            .then(() => {
                alert('Mecânica adicionada')
                setModal(null)
                updateTable()
            })
            .catch(() => alert('Erro ao adicionar esta mecânica!'))
    }

    return (
        <>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container>
                    <Box
                        m={3}
                        fontWeight="bold"
                        color="#666666"
                        fontSize={{
                            xs: 'h7.fontSize',
                            sm: 'h7.fontSize',
                            md: 'h6.fontSize',
                            xl: 'h5.fontSize',
                        }}
                    >
                        Nova Mecânica
                    </Box>

                    <form onSubmit={addMechanic}>
                        <FormContainer>
                            <TextField
                                name="name"
                                label="Nome"
                                variant="outlined"
                                style={{ width: '100%' }}
                                onChange={(event) => setName(event.target.value)}
                                required
                            />
                            <TextField
                                name="address"
                                label="Endereço"
                                variant="outlined"
                                style={{ width: '100%' }}
                                onChange={(event) => setAddress(event.target.value)}
                                required
                            />
                            <Autocomplete
                                disablePortal
                                id="state"
                                options={states}
                                getOptionLabel={(option) =>
                                    option.sigla + ' - ' + option.nome
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.sigla === value.sigla
                                }
                                onChange={(_, value) => setState(value.sigla)}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} required label="Estado" />
                                )}
                                style={{ width: '100%' }}
                            />
                            <Autocomplete
                                disablePortal
                                id="city"
                                options={cities}
                                getOptionLabel={(option) => option.nome}
                                isOptionEqualToValue={(option, value) =>
                                    option.nome === value.nome
                                }
                                onSelect={(event) => setCity(event.target.value)}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} required label="Cidade" />
                                )}
                                style={{ width: '100%' }}
                            />
                            <TextField
                                name="phone"
                                label="Telefone"
                                variant="outlined"
                                style={{ width: '100%' }}
                                onChange={(event) => setPhone(event.target.value)}
                                required
                            />
                        </FormContainer>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            padding="20px"
                            justifySelf="center"
                        >
                            <Button
                                variant="contained"
                                type="submit"
                                style={{ marginRight: '4px' }}
                            >
                                Adicionar
                            </Button>
                            <Button variant="outlined" onClick={() => handleClose()}>
                                Fechar
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Modal>
        </>
    )
}

export default NewMechanicModal
