import baseUrl from '../utils/baseUrl'
import axios from './api'

const getAllClients = async (page, pageSize, sortBy, sortDirection, filterText) =>
    await axios
        .get(
            baseUrl +
                `/users/client/all?pageNumber=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&name=${filterText}&client=true`,
        )
        .then((response) => response.data)

const deleteClient = async (appUserId) => {
    await axios
        .delete(baseUrl + `/users/admin/${appUserId}`)
        .then((response) => response.data)
}

const getClientsByMechanicId = async (
    mechanicId,
    page,
    pageSize,
    sortBy,
    sortDirection,
    filterText,
) =>
    await axios
        .get(
            baseUrl +
                `/mechanics/clients?mechanicId=${mechanicId}&pageNumber=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortBy=${sortBy}&appUserName=${filterText}`,
        )
        .then((response) => response.data)

const refreshAllMechanicClients = async () =>
    await axios
        .post(baseUrl + `/users/admin/refresh-mechanics-reference/all`)
        .then((response) => response.data)

export { getAllClients, deleteClient, getClientsByMechanicId, refreshAllMechanicClients }
