import { Card, List, ListItemText } from '@mui/material'
import styled from 'styled-components'

export const ClientsFormContainer = styled(Card)`
    display: grid;

    grid-template-columns: 400px auto;
    grid-template-rows: auto;

    grid-template-areas: 'CLIENTDATA CLIENTLIST';
`

export const DataContainer = styled.div`
    grid-area: 'CLIENTDATA';
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    direction: column;
    margin: 15px;
    justify-items: space-between;
`

export const ListContainer = styled.div`
    grid-area: 'CLIENTLIST';
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 400px;
    margin: 20px;
    justify-items: space-between;
`

export const StyledList = styled(List)`
    width: 100%;
    justify-content: space-between;
`

export const StyledListItemText = styled(ListItemText)`
    display: flex;
    justify-content: right;
`
