import styled from 'styled-components'

export const Container = styled.span`
    display: flex;
    padding: 10px 17px;
    color: #222;
    font-size: 18px;
    margin-left: 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    &.active,
    &:active,
    &:hover {
        font-weight: bold;
        color: #333333;
        background-color: #fafafa;
    }

    &.active {
        cursor: default;
        color: #006ba8;
        font-weight: bold;
        background-color: #fff;
    }
`
