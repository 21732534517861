import { Card } from '@mui/material'
import styled from 'styled-components'

export const Container = styled.div`
    margin-left: 10px;
`

export const ChartContainer = styled(Card)`
    padding: 15px;
    padding-top: 22.5px;
    padding-bottom: 22.5px;
    width: 97%;
    height: 250px;
    display: flex;
    flex-direction: column;
`

export const CardContainer = styled(Card)`
    padding: 15px;
    width: 98%;
    height: auto;
    display: flex;
    flex-direction: column;
`
