import styled from 'styled-components'

export const Container = styled.div`
    position: absolute;
    top: 50px;
    right: 8px;

    width: 210px;

    background: #f5f5f5;
    box-shadow: 0 12px 28px rgba(125, 125, 125, 0.25);
    z-index: 12;
    font-size: 16px;

    a {
        color: #666666;
        text-decoration: none;
    }
`

export const UserMenuLink = styled.div`
    display: flex;
    padding: 10px 17px;
    cursor: pointer;
    color: #666666;

    &.active,
    &:active,
    &:hover {
        font-weight: bold;
        background-color: #fff;
        > a {
            color: #333333;
        }
    }
`

export const SectionItem = styled.li`
    display: flex;

    color: #999999;
    background-color: #fafafa;
    border-bottom: 1px solid #e5e5e5;

    font-size: 14px;
    padding: 10px 17px 10px 25px;
    cursor: pointer;

    transition:
        color 0.2s,
        background-color 0.2s;

    &.active {
        color: #427cac;
        font-weight: bold;
        cursor: default;
    }

    &:active,
    &:hover {
        font-weight: bold;
    }
`
