import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { Container } from './styles'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css'
import { Typography } from '@mui/material'
import * as IndicationsService from '../../services/indications'

const MechanicIndications = () => {
    const [data, setData] = useState([])
    //pagination states
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)

    useEffect(() => {
        fetchIndications() //fetch page 0 of sales
    }, [])

    async function fetchIndications() {
        setLoading(true)
        const response = await IndicationsService.getAll()
        console.log(response)
        setData(response)
        setTotalRows(response.length)
        setLoading(false)
    }

    const columns = [
        {
            id: 'name',
            name: 'Nome',
            selector: (row) => row.name,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Telefone',
            selector: (row) => row.phone,
            width: '15%',
        },
        {
            name: 'Cidade',
            selector: (row) => row.city,
            width: '15%',
            sortable: true,
        },
        {
            name: 'Estado',
            selector: (row) => row.state,
            width: '10%',
            sortable: true,
        },
        {
            name: 'Endereço',
            selector: (row) => row.address,
            width: '20%',
        },
        {
            name: 'Indicado Por',
            selector: (row) => row.appUserName,
            width: '15%',
        },
    ]

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                fontSize={{ xs: 'h7.fontSize', sm: 'h6.fontSize', md: 'h5.fontSize' }}
            >
                Indicações de Mecânicas
            </Box>
            <Box>
                <Typography
                    fontSize={{
                        xs: 'h7.fontSize',
                        sm: 'h6.fontSize',
                        md: 'h6.fontSize',
                    }}
                >
                    Total: <b>{totalRows} Indicações</b>
                </Typography>
            </Box>
            <Box mt={3} mb={5}>
                <Card>
                    <DataTableExtensions
                        columns={columns}
                        data={data}
                        export={false}
                        print={false}
                    >
                        <DataTable pagination loading={loading} />
                    </DataTableExtensions>
                </Card>
            </Box>
        </Container>
    )
}

export default MechanicIndications
