import styled from 'styled-components'
import Card from '@mui/material/Card'

export const Container = styled(Card)`
    padding: 5px;
    max-width: 1200px;
    max-height: 1800px;
    color: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #f5f5f5;
`

export const FormContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 20px;
    justify-items: space-between;
`
