import styled from 'styled-components'

export const Container = styled.div`
    grid-area: SHELLBAR;

    display: flex;
    align-items: center;
    justify-content: space-between;

    z-index: 3;
    padding: 0 17px;
    font-size: 14px;

    background: #e3e4e6; //#042940;
    /* background: #1259a1; */
    box-shadow:
        2px 0 5px rgba(102, 102, 102, 0.15),
        1px 0 4px rgba(102, 102, 102, 0.25);
`
