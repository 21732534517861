import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import {
    ClientsFormContainer,
    DataContainer,
    ListContainer,
    StyledList,
    StyledListItemText,
} from './styles'
import 'react-data-table-component-extensions/dist/index.css'
import Button from '@mui/material/Button'
import * as ClientsService from '../../../../services/clients'
import { Alert, Divider, ListItem, ListItemText } from '@mui/material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import Table from '../../../../components/ui/Table'
import { useHistory } from 'react-router-dom'

const ClientsData = ({ mechanic }) => {
    const history = useHistory()

    const [clientData, setClientData] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [page, setPage] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [sortDirection, setSortDirection] = useState('ASC')
    const [sortBy, setSortBy] = useState('name')
    const [filterText, setFilterText] = useState('')

    useEffect(() => {
        async function fetchClients() {
            setLoading(true)
            const response = await ClientsService.getClientsByMechanicId(
                mechanic.id,
                page,
                perPage,
                sortBy,
                sortDirection,
                filterText,
            ).catch(() => {
                Alert('Erro ao pegar dados de clientes da mecânica!')
            })
            setClientData(response.content)
            setTotalRows(response.totalElements)
            setLoading(false)
        }

        fetchClients()
    }, [page, perPage, sortDirection, sortBy, filterText, mechanic.id])

    const columns = [
        {
            id: 'name',
            name: 'Nome',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
        },
        {
            name: 'Detalhes',
            cell: (row) => (
                <Button
                    id={row.ID}
                    variant="outlined"
                    onClick={() =>
                        history.push({
                            pathname: '/dashboard/clientes/detalhes',
                            state: { client: row },
                        })
                    }
                >
                    <ManageSearchIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    return (
        <ClientsFormContainer>
            <DataContainer>
                <StyledList dense={true}>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="CLIENTES ATIVOS" />
                        <StyledListItemText>
                            <b>TBD</b>
                        </StyledListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText primary="TOTAL DE CLIENTES" />
                        <StyledListItemText>
                            <b>{totalRows}</b>
                        </StyledListItemText>
                    </ListItem>
                    <Divider />
                </StyledList>
            </DataContainer>
            <ListContainer>
                <Box>
                    <Table
                        columns={columns}
                        data={clientData}
                        isPaginated={true}
                        filterLabel="Filtrar por Nome"
                        setPage={setPage}
                        setPerPage={setPerPage}
                        setSortBy={setSortBy}
                        setSortDirection={setSortDirection}
                        filterText={filterText}
                        setFilterText={setFilterText}
                        totalRows={totalRows}
                        loading={loading}
                    />
                </Box>
            </ListContainer>
        </ClientsFormContainer>
    )
}

export default ClientsData
