import styled from 'styled-components'

export const Container = styled.ul`
    grid-area: SIDENAV;

    background: #f5f5f5; //#006ba8;

    box-shadow:
        0px 0 0px rgba(102, 102, 102, 0.15),
        1px 0 0px rgba(102, 102, 102, 0.25);
    z-index: 2;
    display: block;

    overflow: hidden;
    padding-left: 0px;
    margin: 0px;
`
