import React from 'react'
import { Container } from './styles'
import { ReactComponent as Logo } from '../../../assets/img/OiCarLogoDark.svg'

const LogoSection = () => {
    return (
        <Container style={{ zIndex: 0 }}>
            <Logo style={{ zIndex: -1 }} />
        </Container>
    )
}

export default LogoSection
