import baseUrl from '../utils/baseUrl'
import axios from './api'

const getTokens = async (username, password) => {
    const params = new URLSearchParams()
    params.append('username', username)
    params.append('password', password)

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': 'http://localhost:8080',
            'Access-Control-Allow-Credentials': 'true',
        },
    }

    return await axios
        .post(baseUrl + `/login`, params, config)
        .then((response) => response.data)
}

// const refreshToken = async (refreshToken) => {
//   const body = [
//     {
//       username: 'arnold@gmail.com',
//       password: '1234',
//     },
//   ];
//   return await axios
//     .post(`/login`, body)
//     .then((response) => response.data);
// };

export {
    getTokens,
    //   searchCommercialByPartyId
}
