import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useModalContext } from '../../../contexts/ModalContext'
import TextField from '@mui/material/TextField'
import { Container, FormContainer } from './styles'
import * as PromotionService from '../../../services/promotions'
import Button from '@mui/material/Button'
import { ImageList, ImageListItem, Typography } from '@mui/material'
import { ZoomIn } from '@mui/icons-material'

const ManagePromotionModal = ({ updateTable, mechanicPromotion }) => {
    const { setModal } = useModalContext('')
    const [reason, setReason] = useState(mechanicPromotion.reason ?? '')

    const handleClose = () => setModal(null)

    const approve = async (event) => {
        event.preventDefault()

        const body = {
            mechanicId: mechanicPromotion.mechanicId,
        }

        await PromotionService.approve(body)
            .then(() => {
                alert('Promoção aprovada!')
                setModal(null)
                updateTable()
            })
            .catch(() => alert('Erro aprovar esta promoção!'))
    }

    const deny = async (event) => {
        event.preventDefault()

        const body = {
            mechanicId: mechanicPromotion.mechanicId,
            reason: reason,
        }

        await PromotionService.deny(body)
            .then(() => {
                alert('Promoção negada!')
                setModal(null)
                updateTable()
            })
            .catch(() => alert('Erro negar esta promoção!'))
    }

    return (
        <>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container>
                    <Box
                        m={3}
                        fontWeight="bold"
                        color="#666666"
                        fontSize={{
                            xs: 'h7.fontSize',
                            sm: 'h7.fontSize',
                            md: 'h6.fontSize',
                            xl: 'h5.fontSize',
                        }}
                    >
                        Gerenciar Promoção
                    </Box>
                    <FormContainer>
                        <Typography>
                            <b>Mecânica:</b> {mechanicPromotion.mechanicName}
                        </Typography>
                        {mechanicPromotion.images.length > 0 && (
                            <>
                                <Typography fontSize={16} marginTop={1}>
                                    <b>Imagens</b>
                                </Typography>
                                <ImageList
                                    sx={{ height: 250, maxWidth: 1100 }}
                                    cols={3}
                                    rowHeight={9}
                                >
                                    {mechanicPromotion.images.map((item) => (
                                        <>
                                            <ImageListItem key={item}>
                                                <img
                                                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                                    alt="Upload preview"
                                                    loading="lazy"
                                                />
                                                <Button
                                                    href={item}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    startIcon={<ZoomIn />}
                                                >
                                                    Ampliar
                                                </Button>
                                            </ImageListItem>
                                        </>
                                    ))}
                                </ImageList>
                            </>
                        )}
                        <TextField
                            name="reason"
                            label="Motivo"
                            value={reason}
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(event) => setReason(event.target.value)}
                            multiline
                            rows={10}
                            required
                        />
                    </FormContainer>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding="20px"
                        justifySelf="center"
                    >
                        <Button
                            variant="contained"
                            style={{
                                marginRight: '4px',
                                background: 'green',
                                color: 'white',
                            }}
                            onClick={(event) => approve(event)}
                        >
                            Aprovar
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                marginRight: '4px',
                                background: 'red',
                                color: 'white',
                            }}
                            onClick={(event) => deny(event)}
                        >
                            Negar
                        </Button>
                        <Button variant="outlined" onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </Box>
                </Container>
            </Modal>
        </>
    )
}

export default ManagePromotionModal
