import React, { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
// import { useTheme } from '../../../hooks/theme';
import { useUser } from '../../../hooks/user'

import { Container, UserMenuLink } from './styles'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LogoutIcon from '@mui/icons-material/Logout'
// import UserMenuSection from './UserMenuSection';

const UserMenu = () => {
    const { open, closeMenu } = useUser()
    const { handleLogout } = useAuth()
    const history = useHistory()

    const node = useRef(null)

    const handleClick = useCallback(
        (e) => {
            if (node.current?.contains(e.target)) return
            closeMenu()
        },
        [node, closeMenu],
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [handleClick])

    const handleLogOut = () => {
        handleLogout()
        history.push('/login')
    }

    const IconLabel = ({ pageName, icon }) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ 'margin-right': '5px' }}>{icon}</div>
                {pageName}
            </div>
        )
    }

    return open ? (
        <Container ref={node}>
            <UserMenuLink onClick={() => handleLogOut()}>
                <IconLabel pageName="Logout" icon={<LogoutIcon />} />
            </UserMenuLink>
        </Container>
    ) : null
}

export default UserMenu
