import React from 'react'
import { Container } from './style'

import AppContainer from './components/layout/AppContainer'
import SideNav from './components/layout/SideNav'
import ShellBar from './components/layout/ShellBar'
import LogoSection from './components/layout/LogoSection'
import UserMenu from './components/layout/UserMenu'
import ModalContainer from './components/ui/ModalContainer'

function App() {
    return (
        <Container>
            <LogoSection />
            <ShellBar />
            <UserMenu />

            <SideNav />

            <AppContainer />

            <ModalContainer />
        </Container>
    )
}

export default App
