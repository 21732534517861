import { CircularProgress, Modal } from '@mui/material'
import React from 'react'
import { Container } from './styles'

const ScreenLoading = () => {
    return (
        <Modal open={true}>
            <Container>
                <CircularProgress size={80} color="primary" />
            </Container>
        </Modal>
    )
}

export default ScreenLoading
