import styled from 'styled-components'

export const Container = styled.span`
    display: flex;

    color: #fff;
    background-color: #7190a3;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom: 1px solid #fff;

    font-size: 16px;
    padding: 10px 17px 10px 25px;
    cursor: pointer;

    transition:
        color 0.2s,
        background-color 0.2s;

    &.active {
        color: #006ba8;
        background-color: #fff;
        font-weight: bold;
        cursor: default;
    }

    &:active,
    &:hover {
        font-weight: bold;
    }
`
