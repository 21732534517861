import React, { createContext, useCallback, useContext, useState } from 'react'

const UserContext = createContext({})

const UserProvider = ({ children }) => {
    const [open, setOpen] = useState(false)

    const closeMenu = useCallback(() => {
        setOpen(false)
    }, [])

    const openMenu = useCallback(() => {
        setOpen(true)
    }, [])

    return (
        <UserContext.Provider value={{ open, closeMenu, openMenu }}>
            {children}
        </UserContext.Provider>
    )
}

function useUser() {
    const context = useContext(UserContext)

    if (!context) {
        throw new Error('useUser must be used within an LocationProvider')
    }

    return context
}

export { UserProvider, useUser }
