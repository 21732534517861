import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import { Container } from './styles'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css'
import { Typography } from '@mui/material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import * as MechanicsService from '../../services/mechanics'
import { useHistory } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import NewMechanicModal from './NewMechanicModal'
import { useModalContext } from '../../contexts/ModalContext'

const Mechanics = () => {
    const history = useHistory()
    const { setModal } = useModalContext()
    const [data, setData] = useState([])

    //pagination states
    const [loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0)

    useEffect(() => {
        fetchTableData() //fetch page 0 of sales
    }, [])

    async function fetchTableData() {
        setLoading(true)
        const response = await MechanicsService.getAllMechanics()
        setData(response)
        setTotalRows(response.length)
        setLoading(false)
    }

    const columns = [
        {
            id: 'name',
            name: 'Nome',
            selector: (row) => row.name,
            sortable: true,
            width: '25%',
        },
        {
            name: 'Telefone',
            selector: (row) => row.phone,
            width: '15%',
        },
        {
            name: 'Cidade',
            selector: (row) => row.city,
            width: '15%',
            sortable: true,
        },
        {
            name: 'Estado',
            selector: (row) => row.state,
            width: '10%',
            sortable: true,
        },
        {
            name: 'Endereço',
            selector: (row) => row.address,
            width: '25%',
        },
        {
            name: 'Detalhes',
            cell: (row) => (
                <Button
                    id={row.ID}
                    variant="outlined"
                    onClick={() =>
                        history.push({
                            pathname: '/dashboard/mecânicas/detalhes',
                            state: { mechanic: row },
                        })
                    }
                >
                    <ManageSearchIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                fontSize={{ xs: 'h7.fontSize', sm: 'h6.fontSize', md: 'h5.fontSize' }}
            >
                Mecânicas
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" mr={3}>
                <Button
                    variant="outlined"
                    onClick={() =>
                        setModal(<NewMechanicModal updateTable={fetchTableData} />)
                    }
                >
                    <AddIcon fontSize="small" />
                    Nova Mecânica
                </Button>
                <Typography
                    fontSize={{
                        xs: 'h7.fontSize',
                        sm: 'h6.fontSize',
                        md: 'h6.fontSize',
                    }}
                >
                    Total: <b>{totalRows} Mecânicas</b>
                </Typography>
            </Box>
            <Box mt={3} mb={5}>
                <Card>
                    <DataTableExtensions
                        columns={columns}
                        data={data}
                        export={false}
                        print={false}
                    >
                        <DataTable pagination loading={loading} />
                    </DataTableExtensions>
                </Card>
            </Box>
        </Container>
    )
}

export default Mechanics
