import baseUrl from '../utils/baseUrl'
import axios from './api'

const getAll = async () =>
    await axios.get(baseUrl + `/mechanic-promotion/all`).then((response) => response.data)

const approve = async (body) => {
    return await axios
        .post(baseUrl + `/mechanic-promotion/approve`, body)
        .then((response) => response.data)
}

const deny = async (body) => {
    return await axios
        .post(baseUrl + `/mechanic-promotion/deny`, body)
        .then((response) => response.data)
}

export { getAll, approve, deny }
