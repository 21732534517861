import styled from 'styled-components'
import Card from '@mui/material/Card'
import { Typography } from '@mui/material'

export const Container = styled(Card)`
    padding: 2px;
    max-height: 800px;
    color: #000;

    display: grid;

    grid-template-columns: 500px auto;
    grid-template-rows: auto;

    grid-template-areas: 'USERDATA VEHICLES';
`

export const FormContainer = styled.div`
    grid-area: 'USERDATA';
    display: flex;
    flex-wrap: wrap;
    direction: column;
    height: 400px;
    margin: 20px;
    justify-items: space-between;
`

export const VehiclesContainer = styled.div`
    grid-area: 'VEHICLES';
    background-color: #f5f5f5;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 400px;
    margin: 20px;
    justify-items: space-between;
`

export const StyledTypography = styled(Typography)`
    margin-top: 15px !important;
    margin-left: 15px !important;
`
