import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;

    div {
        display: flex;
        align-items: center;
    }
`

export const Title = styled.div`
    font-weight: bold;

    > a {
        text-decoration: none;
        color: #333333;
    }
`

// export const Separator = styled(CgChevronDoubleRight)`
//   height: 21px;
//   width: 21px;

//   color: #666666;
//   margin: 0 6px;
// `;

export const Description = styled.span`
    color: #000;
`
