import { Card } from '@mui/material'
import styled from 'styled-components'

export const MechanicFormContainer = styled(Card)`
    display: grid;

    grid-template-columns: 400px auto;
    grid-template-rows: auto;

    grid-template-areas: 'MECHANICDATA USERSLIST';
`

export const MechanicDataContainer = styled.div`
    grid-area: 'MECHANICDATA';
    display: flex;
    flex-wrap: wrap;
    direction: column;
    min-height: 400px;
    margin: 20px;
    justify-items: space-between;
`

export const UsersListContainer = styled.div`
    grid-area: 'USERSLIST';
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    border-color: #e0e0e0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 400px;
    margin: 20px;
    justify-items: space-between;
`
