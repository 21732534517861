import baseUrl from '../utils/baseUrl'
import axios from './api'

const getUsersAndVehiclesChart = async () =>
    await axios
        .get(baseUrl + `/admin-dashboard/users-and-vehicles-chart`)
        .then((response) => response.data)

const getCAsChart = async () =>
    await axios
        .get(baseUrl + `/admin-dashboard/cas-chart`)
        .then((response) => response.data)

const getMostFrequentUsers = async () =>
    await axios
        .get(baseUrl + `/admin-dashboard/most-frequent-users-chart`)
        .then((response) => response.data)

export { getUsersAndVehiclesChart, getCAsChart, getMostFrequentUsers }
