import styled from 'styled-components'

export const Container = styled.div`
    padding: 5px;
    color: #000000;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
`
