import React from 'react'
import { useAppLocation } from '../../../../utils/appLocation'

import { Container, Title, Description } from './styles'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Box from '@mui/material/Box'

const Breadcrumb = () => {
    const appLocation = useAppLocation()

    return (
        <Container>
            <Title>
                <Box color={'#000'}>Admin Tool</Box>
            </Title>

            {appLocation.items.map((pathItem) => {
                const pageName = pathItem
                    .replace(/-/g, ' ')
                    .split(' ')
                    .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
                    .join(' ')

                return (
                    <div key={pathItem}>
                        <ArrowRightIcon
                            sx={{
                                color: '#042940',
                                margin: '0 6px',
                                width: '21px',
                                height: '21px',
                            }}
                        />
                        <Description>{pageName}</Description>
                    </div>
                )
            })}
        </Container>
    )
}

export default Breadcrumb
