import styled from 'styled-components'

export const Container = styled.li`
    overflow: hidden;

    > a {
        text-decoration: none;
    }
`

export const SideNavItemsWrapper = styled.div`
    overflow: hidden;
    transition: max-height 0.75s;
    max-height: ${(props) => (props.open ? '20em' : '0')};

    > a {
        position: relative;
        text-decoration: none;
    }
`
