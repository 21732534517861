import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useModalContext } from '../../../contexts/ModalContext'
import TextField from '@mui/material/TextField'
import { Container, FormContainer } from './styles'
import * as NotificationService from '../../../services/notifications'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'

const ApproveOrDenyModal = ({ updateTable, mechanicId, currentReason }) => {
    console.log(currentReason)
    const { setModal } = useModalContext('')
    const [reason, setReason] = useState(currentReason ?? '')

    const handleClose = () => setModal(null)

    const approve = async (event) => {
        event.preventDefault()

        const body = {
            mechanicId: mechanicId,
        }

        await NotificationService.approve(body)
            .then(() => {
                alert('Notificação aprovada!')
                setModal(null)
                updateTable()
            })
            .catch(() => alert('Erro aprovar esta notificação!'))
    }

    const deny = async (event) => {
        event.preventDefault()

        const body = {
            mechanicId: mechanicId,
            reason: reason,
        }

        await NotificationService.deny(body)
            .then(() => {
                alert('Notificação negada!')
                setModal(null)
                updateTable()
            })
            .catch(() => alert('Erro negar esta notificação!'))
    }

    return (
        <>
            <Modal
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container>
                    <Box
                        m={3}
                        fontWeight="bold"
                        color="#666666"
                        fontSize={{
                            xs: 'h7.fontSize',
                            sm: 'h7.fontSize',
                            md: 'h6.fontSize',
                            xl: 'h5.fontSize',
                        }}
                    >
                        Aprovar ou Negar Notificação
                    </Box>
                    <FormContainer>
                        <Typography>ID Mecânica: {mechanicId}</Typography>
                        <TextField
                            name="reason"
                            label="Motivo"
                            value={reason}
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={(event) => setReason(event.target.value)}
                            multiline
                            rows={10}
                            required
                        />
                    </FormContainer>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding="20px"
                        justifySelf="center"
                    >
                        <Button
                            variant="contained"
                            style={{
                                marginRight: '4px',
                                background: 'green',
                                color: 'white',
                            }}
                            onClick={(event) => approve(event)}
                        >
                            Aprovar
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                marginRight: '4px',
                                background: 'red',
                                color: 'white',
                            }}
                            onClick={(event) => deny(event)}
                        >
                            Negar
                        </Button>
                        <Button variant="outlined" onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </Box>
                </Container>
            </Modal>
        </>
    )
}

export default ApproveOrDenyModal
