import baseUrl from '../utils/baseUrl'
import axios from './api'

const getSuperAdminUserByEmail = async (email) =>
    await axios.get(baseUrl + `/users/admin/${email}`).then((response) => response.data)

const getMechanicUsersByMechanicId = async (mechanicId) =>
    await axios
        .get(baseUrl + `/users/mechanic/${mechanicId}/all`)
        .then((response) => response.data)

const createUser = async (mechanicId, body) =>
    await axios
        .post(baseUrl + `/users/admin/mechanic/${mechanicId}/save`, body)
        .then((response) => response.data)

const deleteUser = async (id, body) =>
    await axios
        .delete(baseUrl + `/users/mechanic/${id}`, body)
        .then((response) => response.data)

export { getSuperAdminUserByEmail, getMechanicUsersByMechanicId, createUser, deleteUser }
