import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Container } from './styles'
import { Button } from '@mui/material'

const GenericModal = ({ open, setOpen, title, subText, children }) => {
    const handleClose = () => setOpen(false)

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <Box
                    m={3}
                    fontWeight="bold"
                    color="#666666"
                    fontSize={{
                        xs: 'h7.fontSize',
                        sm: 'h7.fontSize',
                        md: 'h6.fontSize',
                        xl: 'h5.fontSize',
                    }}
                >
                    {title}
                </Box>
                {subText && (
                    <Box
                        m={3}
                        color="#666666"
                        fontSize={{
                            xs: 'h7.fontSize',
                            sm: 'h7.fontSize',
                            md: 'h6.fontSize',
                            xl: 'h5.fontSize',
                        }}
                    >
                        {subText}
                    </Box>
                )}
                <Box display="flex" padding={3} width="100%" mt={2} mb={2}>
                    {children}
                </Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    padding="20px"
                    justifySelf="center"
                >
                    <Button variant="contained" onClick={() => handleClose()}>
                        Fechar
                    </Button>
                </Box>
            </Container>
        </Modal>
    )
}

export default GenericModal
