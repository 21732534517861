import styled from 'styled-components'

export const Container = styled.div`
    grid-area: APPCONTAINER;

    background-color: #d1dae0; //c3c5c7

    overflow: scroll;
    padding: 24px;
    padding-bottom: 0px;

    justify-content: center;
`
