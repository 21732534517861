import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { Container, FormContainer, VehiclesContainer, StyledTypography } from './styles'
import Button from '@mui/material/Button'
import * as VehiclesService from '../../../services/vehicles'
import ConfirmationModal from '../../../components/ui/ConfirmationModal'
import { useHistory, useLocation } from 'react-router-dom'
import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import * as ClientsService from '../../../services/clients'

var moment = require('moment')

const ClientDetailsPage = () => {
    const location = useLocation()
    const history = useHistory()

    const [confirmationModal, setConfirmationModal] = useState({
        open: false,
        choice: 'remove',
    })

    const clientData = location.state.client
    const [vehicleData, setVehicleData] = useState([])

    useEffect(async () => {
        let vehicles = await VehiclesService.getVehiclesByClientId(clientData.id)
        setVehicleData(vehicles)
    }, [])

    const handleDelete = async () => {
        await ClientsService.deleteClient(clientData.id)
            .then((x) => {
                setConfirmationModal(false)
                alert('Cliente removido!')
            })
            .catch((err) => {
                alert('Erro ao remover o cliente!')
            })
            .finally(() => {
                history.push('/dashboard/clientes')
            })
    }

    return (
        <>
            <Box
                mb={3}
                fontWeight="bold"
                display="flex"
                flexDirection="row"
                color="#000"
                fontSize={{
                    xs: 'h7.fontSize',
                    sm: 'h7.fontSize',
                    md: 'h6.fontSize',
                    xl: 'h5.fontSize',
                }}
            >
                <IconButton
                    style={{ marginRight: '10px' }}
                    onClick={() => history.push('/dashboard/clientes')}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>
                <div style={{ paddingTop: '8px' }}>Detalhes Usuário</div>
            </Box>
            <Box
                display="flex"
                justifyContent="flex-end"
                paddingBottom="20px"
                justifySelf="center"
            >
                <Button
                    variant="contained"
                    color="warning"
                    onClick={() => setConfirmationModal({ open: true, choice: 'remove' })}
                    style={{ marginRight: '4px' }}
                >
                    Recalcular Mecânicas-Cliente
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={() => setConfirmationModal({ open: true, choice: 'remove' })}
                    style={{ marginRight: '4px' }}
                >
                    Deletar Usuário
                </Button>
            </Box>
            <Container>
                <FormContainer>
                    <TextField
                        value={clientData.uniqueIdentifier}
                        name="uniqueIdentifier"
                        label="Identificador"
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        style={{ width: '100%' }}
                    />
                    <TextField
                        value={clientData.name}
                        name="name"
                        label="Nome Completo"
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        style={{ width: '100%' }}
                    />
                    <TextField
                        value={clientData.email}
                        name="email"
                        label="Email"
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        style={{ width: '100%' }}
                    />
                    <TextField
                        value={clientData.cpf}
                        name="cpf"
                        label="CPF"
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        style={{ width: '100%' }}
                    />
                    <TextField
                        value={clientData.phone}
                        name="phone"
                        label="Telefone"
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        style={{ width: '100%' }}
                    />
                    <TextField
                        defaultValue={moment(clientData.birthDate)
                            .local()
                            .format('YYYY-MM-DD')}
                        type="date"
                        name="birthDate"
                        label="Data de Nascimento"
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        style={{ width: '100%' }}
                    />
                </FormContainer>
                <VehiclesContainer>
                    <StyledTypography fontSize={{ xs: 'h6.fontSize' }} color="#000">
                        Veículos: {vehicleData.length}
                    </StyledTypography>
                    <List dense={true}>
                        {vehicleData.map((v, i) => {
                            return (
                                <>
                                    <Divider />
                                    <ListItem key={i}>
                                        <ListItemIcon>
                                            <DirectionsCarIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={v.name} />
                                        <ListItemText>
                                            {v.model + ' - ' + v.year}
                                        </ListItemText>
                                        <ListItemText>
                                            Placa - <b>{v.licensePlate}</b>
                                        </ListItemText>
                                        <ListItemText
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'right',
                                            }}
                                        >
                                            <Button variant="outlined" color="error">
                                                Resetar Vendas
                                            </Button>
                                        </ListItemText>
                                    </ListItem>
                                </>
                            )
                        })}
                    </List>
                </VehiclesContainer>
            </Container>
            <ConfirmationModal
                open={confirmationModal.open}
                setOpen={setConfirmationModal}
                title="Deseja deletar este cliente?"
                subText={`Atenção! Esta ação é irreversível!`}
                handleConfirmation={handleDelete}
            />
        </>
    )
}

export default ClientDetailsPage
