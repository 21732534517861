import React, { useState } from 'react'
import Box from '@mui/material/Box'
import 'react-data-table-component-extensions/dist/index.css'
import Button from '@mui/material/Button'
import { ButtonGroup, IconButton } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useHistory, useLocation } from 'react-router-dom'
import MechanicData from './MechanicsData'
import ClientsData from './ClientsData'

const MechanicDetailsPage = () => {
    const history = useHistory()
    const location = useLocation()
    const mechanic = location.state.mechanic

    const [isMechanicData, setIsMechanicData] = useState(true)

    const handleButtonClick = (isCD) => {
        setIsMechanicData(isCD)
    }

    return (
        <>
            <Box
                mb={3}
                fontWeight="bold"
                display="flex"
                flexDirection="row"
                fontSize={{
                    xs: 'h7.fontSize',
                    sm: 'h7.fontSize',
                    md: 'h6.fontSize',
                    xl: 'h5.fontSize',
                }}
            >
                <IconButton
                    style={{ marginRight: '10px' }}
                    onClick={() => history.push('/dashboard/mecânicas')}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>
                <div style={{ paddingTop: '8px' }}>Mecânica {mechanic.name}</div>
            </Box>

            <Box ml={3}>
                <ButtonGroup>
                    <Button
                        variant={isMechanicData ? 'contained' : 'outlined'}
                        onClick={() => handleButtonClick(true)}
                    >
                        Dados da Mecânica
                    </Button>
                    <Button
                        variant={!isMechanicData ? 'contained' : 'outlined'}
                        onClick={() => handleButtonClick(false)}
                    >
                        Clientes
                    </Button>
                </ButtonGroup>
            </Box>

            <Box mt={3} mb={3}>
                {isMechanicData && <MechanicData mechanic={mechanic} />}
                {!isMechanicData && <ClientsData mechanic={mechanic} />}
            </Box>
        </>
    )
}

export default MechanicDetailsPage
