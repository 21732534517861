import React from 'react'
import { AuthProvider } from './useAuth'
import { UserProvider } from './user'
import { ModalProvider } from '../contexts/ModalContext'

// import { UserProvider } from './user';
// import { ThemeProvider } from './theme';
// import { AuthProvider } from './auth';
// import { NotificationProvider } from './notification';

const AppProvider = ({ children }) => {
    return (
        <AuthProvider>
            <ModalProvider>
                <UserProvider>{children}</UserProvider>
            </ModalProvider>
        </AuthProvider>
    )
}

export default AppProvider
