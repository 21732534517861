import { statesUrl } from '../utils/ibgeUrls'
import axios from './api'

const getStates = async () => await axios.get(statesUrl).then((response) => response.data)

const getCities = async (uf) => {
    return await axios
        .get(statesUrl + `/${uf}/municipios`)
        .then((response) => response.data)
}

export { getStates, getCities }
