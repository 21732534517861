import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import { CardContainer, ChartContainer, Container } from './styles'
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import {
    Avatar,
    Card,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material'
import { Email, Phone } from '@mui/icons-material'
import * as DashboardService from '../../services/dashboard'

const caComposedChart = (CAsData) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={CAsData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 10,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="date" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                    type="monotone"
                    dataKey="qtd"
                    fill="#9d9ad9"
                    stroke="#8884d8"
                    strokeWidth={2}
                />
                <Line type="monotone" dataKey="refuel" stroke="#d6b302" strokeWidth={2} />
                <Line
                    type="monotone"
                    dataKey="mechanicService"
                    stroke="#00bfa9"
                    strokeWidth={2}
                />
                <Line type="monotone" dataKey="others" stroke="#ff7300" strokeWidth={2} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

const usersComposedChart = (data) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 10,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="date" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                    type="monotone"
                    dataKey="users"
                    fill="#2c5385"
                    stroke="#132337"
                    strokeWidth={2}
                />
                <Line
                    type="monotone"
                    dataKey="vehicles"
                    stroke="#ff7300"
                    strokeWidth={3}
                />
            </ComposedChart>
        </ResponsiveContainer>
    )
}

const Dashboard = () => {
    const [usersAndVehiclesData, setUsersAndVehiclesData] = useState({
        today: 0,
        yesterday: 0,
        usersAndVehicles: [],
    })
    const [casData, setCasData] = useState({ total: 0, caDetails: [] })
    const [mostFrequentUsers, setMostFrequentUsers] = useState([])

    useEffect(() => {
        async function fetchUsersAndVehiclesData() {
            const response = await DashboardService.getUsersAndVehiclesChart()
            setUsersAndVehiclesData(response)
        }

        async function fetchCAsData() {
            const response = await DashboardService.getCAsChart()
            setCasData(response)
        }

        async function fetchMostFrequentUsers() {
            const response = await DashboardService.getMostFrequentUsers()
            setMostFrequentUsers(response)
        }

        fetchUsersAndVehiclesData()
        fetchCAsData()
        fetchMostFrequentUsers()
    }, [])

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                fontSize={{ xs: 'h7.fontSize', sm: 'h6.fontSize', md: 'h5.fontSize' }}
            >
                Dashboard
            </Box>
            <Box mb={5} display="flex" flexDirection="column" gap={3}>
                <ChartContainer>
                    <Box mb={2} ml={2} fontWeight="bold" fontSize={16}>
                        Usuários e veículos registrados (mês)
                    </Box>
                    <Box mb={2} ml={2} gap={3} display="flex" flexDirection="row">
                        <Box fontSize={15}>
                            Hoje: <b>{usersAndVehiclesData.today}</b>
                        </Box>
                        <Box fontSize={15}>
                            Ontem: <b>{usersAndVehiclesData.yesterday}</b>
                        </Box>
                    </Box>
                    {usersComposedChart(usersAndVehiclesData.usersAndVehicles)}
                </ChartContainer>
                <ChartContainer>
                    <Box mb={2} ml={2} fontWeight="bold" fontSize={16}>
                        Atividades registradas dos clientes (CAs/dia)
                    </Box>
                    <Box mb={2} ml={2} gap={3} display="flex" flexDirection="row">
                        <Box fontSize={15}>
                            Total: <b>{casData.total}</b>
                        </Box>
                    </Box>
                    {caComposedChart(casData.caDetails)}
                </ChartContainer>
                <CardContainer>
                    <Box mb={2} ml={2} fontWeight="bold" fontSize={16}>
                        Usuários mais ativos
                    </Box>
                    <List dense sx={{ width: '100%', bgcolor: '#f5f5f5' }}>
                        {mostFrequentUsers.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${value.email}`
                            return (
                                <ListItem
                                    key={value.email}
                                    disablePadding
                                    secondaryAction={
                                        <Box display="flex" gap={2}>
                                            <Typography>
                                                CAs: <b>{value.cas}</b>
                                            </Typography>
                                            <hr />
                                            <Typography>
                                                <Box display="flex" gap={1}>
                                                    <Email />
                                                    {value.email}
                                                </Box>
                                            </Typography>
                                            <hr />
                                            <Typography>
                                                <Box display="flex" gap={1}>
                                                    <Phone />
                                                    {value.phone}
                                                </Box>
                                            </Typography>
                                        </Box>
                                    }
                                >
                                    <ListItemButton>
                                        <ListItemAvatar>
                                            <Avatar alt={`Avatar ${value.email}`} />
                                        </ListItemAvatar>
                                        <ListItemText id={labelId} primary={value.name} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })}
                    </List>
                </CardContainer>
            </Box>
        </Container>
    )
}

export default Dashboard
