import React from 'react'
import { Link } from 'react-router-dom'

import SideNavSectionItem from './SideNavSectionItem'
import SideNavSectionHeader from './SideNavSectionHeader'

import { Container, SideNavItemsWrapper } from './styles'
import { useAppLocation } from '../../../../utils/appLocation'

const SideNavSection = ({ section }) => {
    const appLocation = useAppLocation()
    const { items, name, icon } = section
    const open = name.toLowerCase() === appLocation.module

    return (
        <Container>
            <Link className="baseLink" key={1} to={items[0].path}>
                <SideNavSectionHeader isSelected={open} pageName={name} icon={icon} />
            </Link>

            <SideNavItemsWrapper open={open}>
                {items?.map((each) => {
                    if (items.length === 1) return
                    const { page, path } = each

                    return (
                        <Link className="baseLink" key={page} to={path}>
                            <SideNavSectionItem
                                isSelected={appLocation.path === path}
                                pageName={page}
                            />
                        </Link>
                    )
                })}
            </SideNavItemsWrapper>
        </Container>
    )
}

export default SideNavSection
