import React from 'react'
import { Route as ReactRouterDomRoute, Redirect } from 'react-router-dom'

import { useAuth } from '../hooks/useAuth'

const Route = ({ isPrivate = false, component: Component, ...rest }) => {
    // const { user } = useAuth();
    // const userExists = Boolean(user);
    const { authenticated } = useAuth()
    return (
        <ReactRouterDomRoute
            render={({ location }) => {
                return isPrivate === authenticated ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: isPrivate ? '/login' : '/dashboard',
                            state: { from: location },
                        }}
                    />
                )
            }}
            {...rest}
        />
    )
}

export default Route
