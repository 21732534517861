import baseUrl from '../utils/baseUrl'
import axios from './api'

const getAllMechanics = async () =>
    await axios.get(baseUrl + `/mechanics/all`).then((response) => response.data)

const saveMechanic = async (body) =>
    await axios.post(baseUrl + '/mechanics/save', body).then((response) => response.data)

const updateMechanic = async (id, body) =>
    await axios.put(baseUrl + `/mechanics/${id}`, body).then((response) => response.data)

export { getAllMechanics, saveMechanic, updateMechanic }
