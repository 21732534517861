import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useModalContext } from '../../../../../contexts/ModalContext'
import TextField from '@mui/material/TextField'
import { Container, FormContainer } from './styles'
import 'react-data-table-component-extensions/dist/index.css'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'
import * as UsersService from '../../../../../services/users'

var moment = require('moment')

const NewUserModal = ({ mechanicId, updateTable }) => {
    const { setModal } = useModalContext()
    const handleClose = () => setModal(null)
    const [inputs, setInputs] = useState({
        birthDate: moment(),
    })

    const handleInputsChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setInputs((values) => ({ ...values, [name]: value }))
    }

    const handleAutocompleteChange = (event, dataValue) => {
        const name = 'role'
        const value = dataValue.label
        setInputs((values) => ({ ...values, [name]: value }))
    }

    const handleAddUser = async (event) => {
        event.preventDefault()

        const body = {
            name: inputs.name,
            email: inputs.email,
            password: inputs.password,
            birthDate: moment(inputs.birthDate).toDate(),
            phone: inputs.phone,
            role: inputs.role === 'ADMIN' ? 'ROLE_ADMIN_MECHANIC' : 'ROLE_USER_MECHANIC',
        }

        await UsersService.createUser(mechanicId, body)
            .then((x) => {
                setModal(null)
                updateTable()
                alert(`Usuário criado com sucesso!`)
            })
            .catch(() => alert(`Erro ao criar este Usuário!`))
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <Box
                    m={3}
                    fontWeight="bold"
                    color="#666666"
                    fontSize={{
                        xs: 'h7.fontSize',
                        sm: 'h7.fontSize',
                        md: 'h6.fontSize',
                        xl: 'h5.fontSize',
                    }}
                >
                    Novo Usuário
                </Box>
                <form onSubmit={handleAddUser}>
                    <FormContainer>
                        <TextField
                            name="name"
                            required
                            label="Nome Completo"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={handleInputsChange}
                        />
                        <TextField
                            name="email"
                            required
                            type="email"
                            label="Email"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={handleInputsChange}
                        />
                        <TextField
                            name="password"
                            required
                            label="Password"
                            type="password"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={handleInputsChange}
                        />
                        <TextField
                            name="birthDate"
                            required
                            defaultValue={moment(inputs.birthDate)
                                .local()
                                .format('YYYY-MM-DD')}
                            type="date"
                            label="Data de Nascimento"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={handleInputsChange}
                        />
                        <TextField
                            name="phone"
                            required
                            label="Telefone"
                            variant="outlined"
                            style={{ width: '100%' }}
                            onChange={handleInputsChange}
                        />
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={permissions}
                            onChange={(event, value) =>
                                handleAutocompleteChange(event, value)
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                                <TextField {...params} required label="Permissão" />
                            )}
                            style={{ width: '100%' }}
                        />
                    </FormContainer>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding="20px"
                        justifySelf="center"
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            style={{ marginRight: '4px' }}
                        >
                            Adicionar
                        </Button>
                        <Button variant="outlined" onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </Box>
                </form>
            </Container>
        </Modal>
    )
}

const permissions = [{ label: 'ADMIN' }, { label: 'USER' }]

export default NewUserModal
