import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import {
    MechanicDataContainer,
    MechanicFormContainer,
    UsersListContainer,
} from './styles'
import 'react-data-table-component-extensions/dist/index.css'
import Button from '@mui/material/Button'
import * as UsersService from '../../../../services/users'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import { Autocomplete, TextField, Typography } from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import * as StatesService from '../../../../services/statesAndCities'
import * as MechanicsService from '../../../../services/mechanics'
import { useModalContext } from '../../../../contexts/ModalContext'
import NewUserModal from './NewUserModal'
import ConfirmationModal from '../../../../components/ui/ConfirmationModal'

const MechanicData = ({ mechanic }) => {
    const { setModal } = useModalContext()
    const [confModal, setConfModal] = useState({
        open: false,
        choice: 'remove',
        userId: null,
    })

    const [users, setUsers] = useState([])

    const [editMode, setEditMode] = useState(false)

    const [name, setName] = useState(mechanic.name)
    const [address, setAddress] = useState(mechanic.address)
    const [state, setState] = useState(mechanic.state)
    const [city, setCity] = useState(mechanic.city)
    const [phone, setPhone] = useState(mechanic.phone)

    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    useEffect(() => {
        fetchUsers()
    }, [mechanic.id])

    async function fetchUsers() {
        const users = await UsersService.getMechanicUsersByMechanicId(mechanic.id)
        users.map((u) => {
            return (u.role = u.role === 'ROLE_USER_MECHANIC' ? 'USER' : 'ADMIN')
        })
        setUsers(users)
    }

    useEffect(() => {
        async function getStates() {
            const response = await StatesService.getStates()
            setStates(response)
        }

        getStates()
    }, [])

    useEffect(() => {
        async function getCities() {
            const response = await StatesService.getCities(state)
            setCities(response)
        }

        getCities()
    }, [state])

    const cancelEditMode = () => {
        setName(mechanic.name)
        setAddress(mechanic.address)
        setState(mechanic.state)
        setCity(mechanic.city)
        setPhone(mechanic.phone)
        setEditMode(false)
    }

    const handleUpdate = async (event) => {
        event.preventDefault()

        const body = {
            name: name,
            address: address,
            state: state,
            city: city,
            phone: phone,
        }

        await MechanicsService.updateMechanic(mechanic.id, body)
            .then(() => {
                alert('Mecânica atualizada')
                setEditMode(false)
            })
            .catch(() => alert('Erro ao atualizar esta mecânica!'))
    }

    const handleDeleteUser = async () => {
        if (confModal.userId === null) return

        await UsersService.deleteUser(confModal.userId)
            .then((x) => {
                fetchUsers()
                setConfModal(false)
                alert(`Usuário deletado com sucesso!`)
            })
            .catch(() => alert(`Erro ao deletar este Usuário!`))
    }

    const columns = [
        {
            id: 'name',
            name: 'Nome',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
        },
        {
            name: 'Permissão',
            selector: (row) => row.role,
        },
        {
            name: 'Ação',
            cell: (row) => (
                <Button
                    id={row.ID}
                    variant="outlined"
                    onClick={() =>
                        setConfModal({ open: true, choice: 'remove', userId: row.id })
                    }
                >
                    <Delete />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    return (
        <MechanicFormContainer>
            <form onSubmit={handleUpdate}>
                <MechanicDataContainer>
                    <Typography fontSize={18}>Detalhes</Typography>
                    <TextField
                        value={name}
                        name="name"
                        label="Nome"
                        InputProps={{ readOnly: !editMode }}
                        onChange={(event) => setName(event.target.value)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        required
                    />
                    <TextField
                        value={address}
                        name="address"
                        label="Endereço"
                        InputProps={{ readOnly: !editMode }}
                        onChange={(event) => setAddress(event.target.value)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        required
                    />
                    {editMode && (
                        <>
                            <Autocomplete
                                disablePortal
                                id="state"
                                options={states}
                                getOptionLabel={(option) =>
                                    option.sigla + ' - ' + option.nome
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.sigla === value.sigla
                                }
                                onChange={(_, value) => setState(value.sigla)}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} required label="Estado" />
                                )}
                                style={{ width: '100%' }}
                                required
                            />
                            <Autocomplete
                                disablePortal
                                id="city"
                                options={cities}
                                getOptionLabel={(option) => option.nome}
                                isOptionEqualToValue={(option, value) =>
                                    option.nome === value.nome
                                }
                                onSelect={(event) => setCity(event.target.value)}
                                sx={{ width: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} required label="Cidade" />
                                )}
                                style={{ width: '100%' }}
                                required
                            />
                        </>
                    )}
                    {!editMode && (
                        <>
                            <TextField
                                value={state}
                                name="state"
                                label="Estado"
                                InputProps={{ readOnly: !editMode }}
                                variant="outlined"
                                style={{ width: '100%' }}
                                required
                            />
                            <TextField
                                value={city}
                                name="city"
                                label="Cidade"
                                InputProps={{ readOnly: !editMode }}
                                variant="outlined"
                                style={{ width: '100%' }}
                                required
                            />
                        </>
                    )}
                    <TextField
                        value={phone}
                        name="phone"
                        label="Telefone"
                        InputProps={{ readOnly: !editMode }}
                        onChange={(event) => setPhone(event.target.value)}
                        variant="outlined"
                        style={{ width: '100%' }}
                        required
                    />
                    <Box
                        width="100%"
                        height="40px"
                        display="flex"
                        justifyContent="right"
                        gap={1}
                    >
                        {!editMode && (
                            <Button variant="text" onClick={() => setEditMode(true)}>
                                Editar
                            </Button>
                        )}
                        {editMode && (
                            <>
                                <Button variant="outlined" type="submit">
                                    Atualizar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => cancelEditMode()}
                                >
                                    Cancelar
                                </Button>
                            </>
                        )}
                    </Box>
                </MechanicDataContainer>
            </form>
            <UsersListContainer>
                <Box m={1}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography fontSize={18}>Usuários: {users.length}</Typography>
                        <Button
                            variant="outlined"
                            onClick={() =>
                                setModal(
                                    <NewUserModal
                                        updateTable={fetchUsers}
                                        mechanicId={mechanic.id}
                                    />,
                                )
                            }
                        >
                            <AddIcon fontSize="small" />
                            Usuário
                        </Button>
                    </Box>
                    <DataTableExtensions
                        columns={columns}
                        data={users}
                        export={false}
                        print={false}
                    >
                        <DataTable pagination />
                    </DataTableExtensions>
                </Box>
            </UsersListContainer>
            <ConfirmationModal
                open={confModal.open}
                setOpen={setConfModal}
                title="Deseja deletar este Usuário?"
                subText={`Atenção! Esta ação é irreversível!`}
                handleConfirmation={handleDeleteUser}
            />
        </MechanicFormContainer>
    )
}

export default MechanicData
