import React from 'react'
import DataTable from 'react-data-table-component'
import 'react-data-table-component-extensions/dist/index.css'
import FilterComponent from '../Filter'

const Table = ({
    columns,
    data,
    isPaginated = false,
    filterLabel,
    totalRows,
    setPage,
    setPerPage,
    setSortDirection,
    setSortBy,
    filterText,
    setFilterText,
    loading,
}) => {
    const handlePageChange = (newPage) => {
        setPage(newPage - 1)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage)
    }

    const handleSortChange = async (column, sortDirection) => {
        setSortBy(column.id)
        setSortDirection(sortDirection.toUpperCase())
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('')
            }
        }

        return (
            <FilterComponent
                onFilter={(e) => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
                placeholder={filterLabel}
            />
        )
    }, [filterText])

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination={isPaginated}
            sortServer
            onSort={handleSortChange}
            progressPending={loading}
            paginationServer={isPaginated}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            subHeader={filterLabel ? true : false}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
        />
    )
}

export default Table
