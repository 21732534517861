import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import Box from '@mui/material/Box'
import { Button, Card } from '@mui/material'
import { useModalContext } from '../../contexts/ModalContext'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css'
import * as PromotionsService from '../../services/promotions'
import RuleFolderIcon from '@mui/icons-material/RuleFolder'
import ManagePromotionModal from './ManagePromotionModal'

const Status = {
    APPROVED: {
        text: 'APROVADO / EM EXECUÇÃO',
        color: 'green',
    },
    PENDING: {
        text: 'ESPERANDO APROVAÇÃO',
        color: 'blue',
    },
    DENIED: {
        text: 'NEGADO',
        color: 'red',
    },
    NOTSENT: {
        text: 'ESPERANDO CADASTRO',
        color: 'gray',
    },
}

const Promotions = () => {
    const { setModal } = useModalContext()
    const [data, setData] = useState([])

    useEffect(() => {
        fetchTableData()
    }, [])

    async function fetchTableData() {
        await PromotionsService.getAll().then((response) => {
            console.log(response)
            setData(response)
        })
    }

    const columns = [
        {
            id: 'mechanicId',
            name: 'ID Mecânica',
            selector: (row) => row.mechanicId,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Nome',
            selector: (row) => row.mechanicName,
            sortable: true,
            width: '30%',
        },
        {
            name: 'Status',
            selector: (row) => (
                <div style={{ color: Status[row.status].color }}>{row.status}</div>
            ),
            width: '40%',
        },
        {
            name: 'Gerenciar',
            cell: (row) => (
                <Button
                    id={row.ID}
                    variant="outlined"
                    style={{ background: 'blue', color: 'white' }}
                    onClick={() =>
                        setModal(
                            <ManagePromotionModal
                                updateTable={fetchTableData}
                                mechanicPromotion={row}
                            />,
                        )
                    }
                >
                    <RuleFolderIcon />
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]

    return (
        <Container>
            <Box
                mb={3}
                fontWeight="bold"
                fontSize={{ xs: 'h7.fontSize', sm: 'h6.fontSize', md: 'h5.fontSize' }}
            >
                Promoções
            </Box>
            <Box
                mb={3}
                fontSize={{ xs: 'h9.fontSize', sm: 'h9.fontSize', md: 'h8.fontSize' }}
            >
                Promoções por mecânica
            </Box>
            <Box mt={3} mb={5}>
                <Card>
                    <DataTableExtensions
                        columns={columns}
                        data={data}
                        export={false}
                        print={false}
                    >
                        <DataTable pagination />
                    </DataTableExtensions>
                </Card>
            </Box>
        </Container>
    )
}

export default Promotions
