import React, { useState, useContext } from 'react'

const ModalContext = React.createContext()
const { Provider, Consumer } = ModalContext

export const useModalContext = () => useContext(ModalContext)

const ModalProvider = ({ children }) => {
    const [modal, setModal] = useState()

    return <Provider value={{ modal, setModal }}>{children}</Provider>
}

export { ModalProvider, Consumer as ConsumerProvider }
export default ModalContext
