import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh;
    width: 100vw;

    display: grid;

    justify-items: center;
    align-items: center;

    background-color: #d1dae0;
`

export const FormContainer = styled.div`
    height: 450px;
    width: 450px;

    border-radius: 30px;

    background-color: #f5f5f5;

    box-shadow:
        2px 0 5px rgba(102, 102, 102, 0.15),
        1px 0 4px rgba(102, 102, 102, 0.25);
`
