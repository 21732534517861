import GroupIcon from '@mui/icons-material/Group'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import Build from '@mui/icons-material/Build'
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import InsightsIcon from '@mui/icons-material/Insights'
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded'

export const SideNavItems = {
    app: 'dashboard',
    modules: [
        {
            items: [
                {
                    path: '/dashboard',
                    page: 'Dashboard',
                },
            ],
            icon: <BarChartRoundedIcon />,
            name: 'Dashboard',
        },
        {
            items: [
                {
                    path: '/dashboard/clientes',
                    page: 'Página de Clientes',
                },
            ],
            icon: <GroupIcon />,
            name: 'Clientes',
        },
        {
            items: [
                {
                    path: '/dashboard/mecânicas',
                    page: 'Página de Mecânicas',
                },
            ],
            icon: <Build />,
            name: 'Mecânicas',
        },
        {
            items: [
                {
                    path: '/dashboard/promoções',
                    page: 'Página de Promoções',
                },
            ],
            icon: <InsightsIcon />,
            name: 'Promoções',
        },
        {
            items: [
                {
                    path: '/dashboard/notificações',
                    page: 'Página de Notificações',
                },
            ],
            icon: <NotificationsActiveIcon />,
            name: 'Notificações',
        },
        {
            items: [
                {
                    path: '/dashboard/indicações',
                    page: 'Indicações',
                },
            ],
            icon: <EmergencyShareIcon />,
            name: 'Indicações',
        },
        {
            items: [
                {
                    path: '/dashboard/auditoria',
                    page: 'Auditoria',
                },
            ],
            icon: <ManageSearchIcon />,
            name: 'Auditoria',
        },
    ],
}
