import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Container, FormContainer } from './styles'
import 'react-data-table-component-extensions/dist/index.css'
import Button from '@mui/material/Button'

const ConfirmationModal = ({ open, setOpen, title, subText, handleConfirmation }) => {
    const handleClose = () => setOpen({ open: false, choice: 'none' })

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container>
                <Box
                    m={3}
                    fontWeight="bold"
                    color="#666666"
                    fontSize={{
                        xs: 'h7.fontSize',
                        sm: 'h7.fontSize',
                        md: 'h6.fontSize',
                        xl: 'h5.fontSize',
                    }}
                >
                    {title}
                </Box>
                <Box
                    m={3}
                    color="#666666"
                    fontSize={{
                        xs: 'h7.fontSize',
                        sm: 'h7.fontSize',
                        md: 'h6.fontSize',
                        xl: 'h5.fontSize',
                    }}
                >
                    {subText}
                </Box>
                <FormContainer>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        padding="20px"
                        justifySelf="center"
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleConfirmation}
                            style={{ marginRight: '10px' }}
                        >
                            Confirmar
                        </Button>
                        <Button variant="contained" onClick={() => handleClose()}>
                            Fechar
                        </Button>
                    </Box>
                </FormContainer>
            </Container>
        </Modal>
    )
}

export default ConfirmationModal
